<template>
  <div class="forgetPassword">
    <v-row justify="center" align="center">
      <v-col cols="12" md="6">
        <v-card class="my-2 mx-10">
          <!-- <h4 class="red--text text-center">*請至信箱領取新密碼</h4> -->
          <v-form ref="form">
            <v-card-title
              >更改密碼
              <v-divider vertical class="mx-2"></v-divider>
              <!-- <v-btn text small @click="changeMode()">點擊編輯</v-btn> -->
            </v-card-title>
            <v-divider class="mx-5"></v-divider>
            <v-card-text>
              <v-row class="justify-end">
                <!-- <v-col cols="4">
                  <v-subheader>信箱</v-subheader>
                </v-col>
                <v-col cols="8">
                  <v-text-field
                    v-model="user.email"
                    :rules="rules.emailRules"
                    placeholder="信箱"
                    solo
                    :readonly="!editEmailMode"
                  ></v-text-field>
                </v-col> -->
                <label for="PassWord">舊密碼</label>
                <v-text-field
                  v-model="oldPassWord"
                  name="PassWord"
                  id="PassWord"
                  :rules="rules.required"
                  placeholder="請輸入您的舊密碼"
                  type="password"
                  class="pt-2"
                >
                </v-text-field>
                <label for="password">新密碼</label>
                <v-text-field
                  v-model="password"
                  name="password"
                  id="password"
                  :rules="rules.required"
                  placeholder="請輸入您的新密碼"
                  type="password"
                  class="pt-2"
                >
                </v-text-field>
                <label for="confirmPassword">再次輸入密碼</label>
                <v-text-field
                  v-model="confirmPassword"
                  name="confirmPassword"
                  id="confirmPassword"
                  :rules="rules.required"
                  @keyup.enter="changePassword"
                  placeholder="請再次輸入您的新密碼"
                  type="password"
                  class="pt-2"
                >
                </v-text-field>
              </v-row>
            </v-card-text>
            <v-card-actions>
              <v-col>
                <v-btn color="primary" @click="changePassword">儲存</v-btn>
              </v-col>
            </v-card-actions>
          </v-form>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { showLoading, hideLoading } from '@/utils/loading';
import store from '@/store';
import axios from 'axios';

export default {
  data() {
    return {
      rules: {
        required: [(value) => !!value || '必填欄位'],
        emailRules: [(v) => /.+@.+/.test(v) || '請輸入正確電子信箱'],
      },
      oldPassWord: '',
      password: '',
      confirmPassword: '',
    };
  },
  methods: {
    changePassword() {
      if (!this.$refs.form.validate()) {
        return this.$Message.error('請確認欄位都已填妥!');
      }
      if (this.password !== this.confirmPassword) {
        return this.$Message.error('請再次確認新密碼是否有誤!');
      }
      showLoading();
      axios
        .post('http://web.hilary.com.tw:8085/getapi?type=changepass', {
          member_id: store.state.userInfo.member_id,
          oldpass: this.oldPassWord,
          password: this.password,
        })
        .then((res) => {
          if (res.status == 200 && res.data.success == true) {
            hideLoading();
            this.$Message.success('更改密碼成功!請重新登入!');
            setTimeout(() => {
              localStorage.clear();
              location.href = '/';
            }, 1000);
          } else {
            this.$Message.error(res.data.error);
            hideLoading();
          }
        })
        .catch((e) => {
          this.$Message.error(e);
          hideLoading();
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.justify-end {
  flex-direction: column;
  padding: 0.5rem;
  label {
    font-size: 16px;
  }
}
</style>
