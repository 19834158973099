import { get, post } from "@/utils/http";

export const signupPublicAPI = {
  get: function(paramObj) {
    return get("signup", paramObj, {
      headers: { requiresAuth: false }
    });
  },
  check_introducer: function(paramObj) {
    return post("signup/introducer", paramObj, {
      headers: { requiresAuth: false }
    });
  },
  check_previous: function(paramObj) {
    return get("signup/previous", paramObj, {
      headers: { requiresAuth: false }
    });
  },
  get_email_code: function(paramObj) {
    return post("signup/get_email_code", paramObj, {
      headers: { requiresAuth: false }
    });
  },
  check_email_code: function(paramObj) {
    return post("signup/check_email_code", paramObj, {
      headers: { requiresAuth: false }
    });
  },
  post_register: function(paramObj) {
    return post("signup/register", paramObj, {
      headers: { requiresAuth: false }
    });
  },
  get_member_id: function(paramObj) {
    return get("signup/get_member_id", paramObj, {
      headers: { requiresAuth: false }
    });
  }
};
