<template>
  <div class="login">
    <v-container fluid>
      <v-row justify="center">
        <h3 class="login__title--mobile">會員登入</h3>
      </v-row>
      <v-form class="login__form">
        <v-row justify="center">
          <v-col md="4" class="login__bg pa-0">
            <v-img :src="require('@/assets/images/mall/login_bg_dt.png')" min-height="100%"> </v-img>
          </v-col>

          <v-col md="4" class="login__card">
            <h3 class="login__title--desktop">會員登入</h3>
            <label for="UserName">會員編號</label>
            <v-text-field
              v-model="UserName"
              name="UserName"
              id="UserName"
              placeholder="請輸入您的會員編號"
              class="pt-2"
              autofocus
            >
            </v-text-field>

            <label for="PassWord">密碼</label>
            <v-text-field
              v-model="PassWord"
              name="PassWord"
              id="PassWord"
              placeholder="請輸入您的密碼"
              type="password"
              class="pt-2"
              @keyup.enter="login()"
            >
            </v-text-field>
            <div class="login__helper">
              <p><router-link to="/forgetuserid">忘記編號</router-link></p>
              <p><router-link to="/forgetpassword">忘記密碼</router-link></p>
            </div>
            <v-btn
              elevation="2"
              large
              color="primary"
              width="100%"
              class="login__btn__login mt-5 mb-10"
              @click="login()"
            >
              登入
            </v-btn>
            <v-btn to="signup" elevation="2" large color="#E6F1FF" width="100%" class="login__btn__signup blue--text">
              我要加入會員
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-container>
  </div>
</template>

<script>
import { loginAPI } from '@/api/login/api';
export default {
  data: () => ({
    UserName: '',
    PassWord: '',
  }),
  methods: {
    login() {
      loginAPI.post({ username: this.UserName, password: this.PassWord }).then((res) => {
        this.$store.dispatch('doSetToken', res.data.data.token);
        this.$store.dispatch('doSetUserInfo', res.data.data.user_info);
        this.$router.push('/');
      });
    },
  },
};
</script>

<style lang="scss">
.login {
  background-color: var(--background-color);
}
.login__form {
  padding: 30px;
}
.login__card {
  background-color: #ffffff;
}
.login__title--desktop {
  margin-bottom: 24px;
  text-align: center;
  font-weight: 500;
}
.login__title--mobile {
  display: none;
  margin: 23px 0px;
  font-weight: 500;
}
.login__helper {
  display: flex;
  justify-content: space-between;
  a {
    text-decoration: unset;
  }
}
.login__btn__signup {
  border: 1px solid #0075ff !important;
}
.login__btn__login,
.login__btn__signup {
  font-weight: 500;
}
label {
  font-weight: 500;
  font-size: 16px;
}
input {
  border-radius: 6px;
  background-color: #eeeff2;
}
@media (min-width: 768px) {
  .login {
    padding: 10vh 10vw;
  }
}
@media (max-width: 768px) {
  .login__bg {
    display: none;
  }
  .login__form {
    background-color: white;
  }
  .login__title--mobile {
    display: block;
    text-align: center;
  }
  .login__title--desktop {
    display: none;
  }
}
</style>
