import { get, post } from "@/utils/http";

export const loginAPI = {
  get: function(paramObj) {
    return get("user/access-token", paramObj);
  },
  getOne: function(paramObj) {
    return get(`user/access-token/${paramObj}`);
  },
  post: function(paramObj) {
    return post("user/access-token", paramObj);
  },
  verifyEmail: function(paramObj) {
    return get("user/verifyemail", paramObj, {
      headers: { requiresAuth: false }
    });
  }
};
