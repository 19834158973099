<template>
  <div class="logout"></div>
</template>

<script>
export default {
  created() {
    localStorage.clear();
    location.href = "/";
  }
};
</script>
