<template>
  <div class="forgetPassword">
    <v-row justify="center" align="center">
      <v-col cols="12" md="6">
        <v-card class="my-2 mx-10">
          <h4 class="red--text text-center">*請至信箱查看會員編號</h4>
          <v-form ref="form">
            <v-card-title
              >忘記會員編號
              <v-divider vertical class="mx-2"></v-divider>
              <!-- <v-btn text small @click="changeMode()">點擊編輯</v-btn> -->
            </v-card-title>
            <v-divider class="mx-5"></v-divider>
            <v-card-text>
              <v-row class="justify-end">
                <label for="email">信箱</label>
                <v-text-field
                  v-model="email"
                  name="email"
                  id="email"
                  :rules="rules.emailRules"
                  placeholder="請輸入您的信箱"
                  type="text"
                  class="pt-2"
                >
                </v-text-field>
              </v-row>
            </v-card-text>
            <v-card-actions>
              <v-col>
                <v-btn color="primary" @click="forgetPassword">{{ getNewPasswordText }}</v-btn>
              </v-col>
            </v-card-actions>
          </v-form>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { showLoading, hideLoading } from '@/utils/loading';
import store from '@/store';
import axios from 'axios';

export default {
  data() {
    return {
      rules: {
        required: [(value) => !!value || '必填欄位'],
        emailRules: [(v) => /.+@.+/.test(v) || '請輸入正確電子信箱'],
      },
      userId: '',
      email: '',
      getNewPasswordText: '取得會員編號',
      emailAlert: false,
    };
  },
  methods: {
    forgetPassword() {
      console.log(this.userId, this.email);
      if (!this.$refs.form.validate()) {
        return this.$Message.error('信箱格式錯誤!');
      }
      if (this.email.trim() === '') {
        return this.$Message.error('信箱不得為空!');
      }
      showLoading();
      axios
        .post('http://web.hilary.com.tw:8085/getapi?type=forgotid', {
          email: this.email,
        })
        .then((res) => {
          if (res.status == 200 && res.data.success == true) {
            hideLoading();
            this.emailAlert = true;
            //重新獲得驗證碼倒計時
            let n = 180;
            this.getNewPasswordText = '重新獲得會員編號' + n + 's';
            const run = setInterval(() => {
              n = n - 1;
              if (n < 0) {
                clearInterval(run);
              }
              this.getNewPasswordText = '重新獲得會員編號' + n + 's';
              if (this.getNewPasswordText < '重新獲得新密碼' + 0 + 's') {
                this.emailAlert = false;
                this.getNewPasswordText = '重新獲得會員編號';
              }
            }, 1000);
            this.$Message.success('已發送會員編號至您的信箱!');
          } else {
            console.log(res.data);
            this.$Message.error(res.data.error);
            hideLoading();
          }
        })
        .catch((e) => {
          this.$Message.error(e);
          hideLoading();
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.justify-end {
  flex-direction: column;
  padding: 0.5rem;
  label {
    font-size: 16px;
  }
}
</style>
