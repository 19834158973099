<template>
  <div>
    <h1 class="text-center">{{ message }}</h1>
  </div>
</template>

<script>
import { loginAPI } from "@/api/login/api";
export default {
  data() {
    return {
      message: ""
    };
  },
  created() {
    loginAPI
      .verifyEmail({ token: this.$route.query.token })
      .then(() => {
        this.message = "信箱更改成功";
      })
      .catch(() => {
        this.message = "Token Error!";
      });
  }
};
</script>
